<template>
  <div>
    <ul>
      <li>
        <table class="min-w-full mt-4">
          <thead>
            <tr>
              <th class="w-1/2 text-left">
                {{ $t('general.assetClass-' + data.assetTypeIdentifier) }}
              </th>
              <th class="w-1/4 text-center" v-if="showHeader">
                <h4>
                  {{ $t('analysisAndBenchmark.consumptionValues-assetValue') }}
                </h4>
              </th>
              <th class="w-1/4 text-center" v-if="showHeader">
                <h4>
                  {{
                    $t('analysisAndBenchmark.consumptionValues-strandingPoint')
                  }}
                </h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="w-1/2 text-left">
                {{
                  $t(
                    'analysisAndBenchmark.consumptionValues-finalEnergyConsumption'
                  )
                }}
              </td>
              <td class="text-center value">
                <span>{{ data.finalEnergyConsumption ?? '-' }}</span>
              </td>
              <td class="text-center value">
                <span>{{
                  data.finalEnergyConsumptionStrandingPoint ?? '-'
                }}</span>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 text-left">
                {{ $t('analysisAndBenchmark.consumptionValues-co2Emission') }}
              </td>
              <td class="text-center value">
                <span>{{ data.co2Emission ?? '-' }}</span>
              </td>
              <td class="value text-center">
                <span>{{ data.co2EmissionStrandingPoint ?? '-' }}</span>
              </td>
            </tr>
            <tr>
              <td class="w-1/2 text-left">
                {{
                  $t('analysisAndBenchmark.consumptionValues-waterConsumption')
                }}
              </td>
              <td class="text-center value">
                <span>{{ data.waterConsumption ?? '-' }}</span>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="w-1/2 text-left">
                {{
                  $t('analysisAndBenchmark.consumptionValues-wasteProduction')
                }}
              </td>
              <td class="text-center value">
                <span>{{ data.wasteProduction ?? '-' }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ConsumptionEmissionsStrandingPointsItem',
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
h4 {
  font-size: 12px;
  font-weight: 400;
}
td.value span {
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  padding: 4px 8px;
  width: 80px;
}
table td {
  font-size: 13px;
}
</style>
