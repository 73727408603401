<template>
  <div class="chart-container">
    <DoughnutChart
      v-if="data?.datasets?.length"
      :datasets="data.datasets"
      :labels="data.labels"
      :chartOptions="options"
      :height="chartHeight"
      :backgroundColor="data.backgroundColor"
    />
  </div>
</template>
<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { computed } from 'vue'

export default {
  components: {
    DoughnutChart,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    rightPadding: {
      type: Number,
      default: 10,
    },
    chartHeight: {
      type: Number,
    },
  },
  setup(props) {
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          display: false,
          grid: {
            display: false,
          },
        },
        x: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'start',
          fullSize: false,
          labels: {
            generateLabels: function (chart) {
              const data = chart.data
              return data.labels.map((label, i) => ({
                text: `${data.datasets[0].data[i]}%: ${label}`,
                fillStyle: data.datasets[0].backgroundColor[i],
                hidden: chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              }))
            },
            font: {
              size: 8,
            },
            boxWidth: 8,
            padding: 8,
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
        title: {
          display: true,
          text: props.title,
          padding: {
            bottom: 20,
          },
          align: 'start',
        },
      },
      layout: {
        padding: {
          right: props.rightPadding, // Use prop value for right padding
        },
      },
    }))

    return {
      options,
    }
  },
}
</script>
<style scoped>
.chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
