<template>
  <div class="dynamic-view border p-4 h-full flex flex-col">
    <h2 class="text-center text-xl font-bold mb-4">{{ headline }}</h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BenchmarkDynamicView',
  props: {
    headline: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.dynamic-view {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
}
</style>
