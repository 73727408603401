<template>
  <div>
    <table class="asset-table min-w-full mt-4 border border-1 border-[#ccc]">
      <tbody>
        <tr>
          <td class="w-1/3 border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-objectName') }}
          </td>
          <td class="w-2/3 border border-1 border-[#ccc] text-[13px] px-0">
            <template v-if="groupData && groupData.assets">
              <custom-select
                :options="assetOptions"
                v-model="selectedAsset"
              ></custom-select>
            </template>
          </td>
        </tr>

        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-streetAndNumber') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.street }} {{ assetData?.streetNumber }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-zipAndCity') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.zip }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-country') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.country }}
          </td>
        </tr>
        <tr>
          <td class="w-1/3 border border-1 border-[#ccc] text-[13px] p-1">
            {{
              $t('analysisAndBenchmark.assetDataValues-questionnaireVersion')
            }}
          </td>
          <td class="w-2/3 border border-1 border-[#ccc] text-[13px] px-0">
            <template v-if="assetData && assetData.questionnaires">
              <custom-select
                :options="questionnairesOptions"
                v-model="selectedQuestionnaire"
              ></custom-select>
            </template>
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-assetClass') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetTypes }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{
              $t(
                'analysisAndBenchmark.assetDataValues-constructionAndRenovationYear'
              )
            }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.constructionYear || '-' }} /
            {{ assetData?.renovationYear || '-' }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-featureQuality') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData && featureQuality(assetData.quality) }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-assetTotalArea') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.assetTotalArea }} m²
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-assetUseableArea') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.usableArea }} m²
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ $t('analysisAndBenchmark.assetDataValues-numberOfTenant') }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData?.rentingPeople }}
          </td>
        </tr>
        <tr>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{
              $t('analysisAndBenchmark.assetDataValues-historicalProtection')
            }}
          </td>
          <td class="border border-1 border-[#ccc] text-[13px] p-1">
            {{ assetData && historicProtection(assetData.historicProtection) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useRequest } from '@/composition'
import { reactive, ref } from 'vue'
import {
  objectGroups as objectGroupsApi,
  objects as objectsApi,
} from '@/services/api'
import { customSelect } from '@/components/form'
import { object as objectHelpers } from '@/helpers'

export default {
  name: 'AssetData',
  components: {
    customSelect,
  },
  props: {
    groupId: {
      type: String,
      default: '8',
    },
  },
  data() {
    return {
      selectedQuestionnaire: 0,
      groupData: null,
      assetData: null,
    }
  },
  setup(props) {
    const selectedAsset = ref(0)
    const assetsListRequest = useRequest(() =>
      objectGroupsApi.getAssets(props.groupId)
    )
    const assetDataRequest = useRequest(() =>
      objectsApi.getAssetBenchmarkData(selectedAsset.value)
    )
    return reactive({
      assetsListRequest,
      assetDataRequest,
      selectedAsset,
    })
  },
  computed: {
    assetTypes() {
      return this.assetData?.assetAssetTypes
        .map(item => this.$t('general.assetClass-' + item.assetClassIdentifier))
        .join(' / ')
    },
    assetOptions() {
      return this.groupData.assets.map(item => {
        return {
          id: item.assetId,
          label: item.name,
        }
      })
    },
    questionnairesOptions() {
      return this.assetData.questionnaires.map(item => {
        return { id: item.id, label: item.title }
      })
    },
  },
  methods: {
    async fetchData() {
      try {
        this.groupData = await this.assetsListRequest.request()
        this.selectedAsset = this.groupData.assets[0].assetId
        this.$emit('assetChanged', this.selectedAsset)
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
    async fetchAssetData() {
      try {
        this.assetData = await this.assetDataRequest.request()
        this.selectedQuestionnaire = this.assetData?.questionnaires
          ?.sort(
            (a, b) => (b.closedAt ?? -Infinity) - (a.closedAt ?? -Infinity)
          )
          .find(item => item.closedAt !== null)?.id
        if (this.selectedQuestionnaire == undefined)
          this.selectedQuestionnaire = this.assetData?.questionnaires?.sort(
            (a, b) => b.id - a.id
          )[0]?.id
        this.$emit('questionnaireChanged', this.selectedQuestionnaire)
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
    changeAsset() {
      this.$emit('assetChanged', this.selectedAsset)
      this.fetchAssetData()
    },
    changeQuestionnaire() {
      this.$emit('questionnaireChanged', this.selectedQuestionnaire)
    },
    historicProtection(historicProtection) {
      return objectHelpers.historicProtectionName(historicProtection)
    },
    featureQuality(featureQuality) {
      return objectHelpers.qualityName(featureQuality)
    },
  },
  watch: {
    selectedAsset() {
      this.changeAsset()
    },
    selectedQuestionnaire() {
      this.changeQuestionnaire()
    },
  },
  async created() {
    await this.fetchData()
    await this.fetchAssetData()
  },
}
</script>
