<template>
  <div class="grid grid-cols-1 gap-4 h-full">
    <div class="h-full flex items-end">
      <DoughnutChartScore
        :data="chartData"
        :title="$t('analysisAndBenchmark.EcorePortfolioScoreChartTitle')"
      />
    </div>
    <div class="flex justify-center items-center h-full">
      <Button class="px-4 py-1" @click="openImage">{{
        $t('analysisAndBenchmark.EcorePortfolioScoreButtonText')
      }}</Button>
    </div>
  </div>
</template>

<script>
import { objectGroups as objectGroupsApi } from '@/services/api'
import { useRequest } from '@/composition'
import { mapActions } from 'vuex'
import { reactive, ref } from 'vue'
import DoughnutChartScore from './DoughnutChartScore.vue'
import Button from '@/components/form/button/Button.vue'

export default {
  components: { DoughnutChartScore, Button },
  props: {
    groupId: {
      type: String,
    },
  },
  setup(props) {
    const scoreRequest = useRequest(() =>
      objectGroupsApi.getScore(props.groupId)
    )

    const chartData = ref({ labels: [], datasets: [], backgroundColor: [] })
    return reactive({
      scoreRequest,
      chartData,
    })
  },

  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchData() {
      try {
        this.data = await this.scoreRequest.request()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
    createChart() {
      this.chartData.labels = ['', '']
      this.chartData.datasets = [this.data.score, 100 - this.data.score]
      this.chartData.backgroundColor = ['#26beb8', '#f0f0f0']
    },
    openImage() {
      const { href } = this.$router.resolve({ name: 'referenceImage' })
      window.open(href, '_blank')
    },
  },
  async created() {
    await this.fetchData()
    this.createChart()
  },
}
</script>

<style></style>
