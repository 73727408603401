<template>
  <div class="flex items-center h-full">
    <img
      class="esg-img"
      src="@images/image-esg-performance-path.png"
      alt="Esg performance pathway"
    />
  </div>
</template>

<script>
export default {
  name: 'EsgPerformancePath',
  data() {
    return {}
  },
}
</script>

<style scoped>
img.esg-img {
  width: 100%;
}
</style>
