<template>
  <div v-if="data">
    <h3 class="text-darkGray text-xs font-bold mb-[25px] mx-auto text-left">
      {{ $t('analysisAndBenchmark.portfolioStructure-avarageValue') }}
    </h3>
    <div class="grid grid-cols-1 gap-2 items-center w-full pr-16">
      <div class="mx-auto w-full">
        <div
          class="
            flex-shrink-0
            bg-lightestGray
            border-1 border-darkGray
            rounded-md
            py-1
            px-0.5
            text-[10px] text-left
            border-2
            text-darkGray
            w-full
          "
        >
          <span class="font-bold">
            ⌀{{ $t('analysisAndBenchmark.constructionYear') }}/{{
              $t('analysisAndBenchmark.renovationYear')
            }}</span
          >: {{ data.constructionYear }}/{{ data.renovationYear }}
        </div>
      </div>
      <div class="mx-auto w-full">
        <div
          class="
            flex-shrink-0
            bg-lightestGray
            border-1 border-darkGray
            rounded-md
            py-1
            px-0.5
            text-[10px] text-left
            border-2
            text-darkGray
            w-full
          "
        >
          <span class="font-bold">
            ⌀{{ $t('analysisAndBenchmark.totalArea') }}:</span
          >
          {{ data.totalArea }} m²
        </div>
      </div>
      <div class="mx-auto w-full">
        <div
          class="
            flex-shrink-0
            bg-lightestGray
            border-1 border-darkGray
            rounded-md
            py-1
            px-0.5
            text-[10px] text-left
            border-2
            text-darkGray
            w-full
          "
        >
          <span class="font-bold"
            >⌀{{ $t('analysisAndBenchmark.usableArea') }}</span
          >: {{ data.usableArea }} m²
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style></style>
