<template>
  <div class="underline-first-word">
    <span v-html="underlinedFirstWord"></span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    underline: {
      type: Boolean,
    },
  },
  computed: {
    underlinedFirstWord() {
      if (!this.underline) return `<span>${this.text}</span>`
      const words = this.text.split(' ')
      if (words.length > 1) {
        const firstWord = words.shift()
        return `<span class="underline">${firstWord}</span> ${words.join(' ')}`
      } else {
        return `<span class="underline">${this.text}</span>`
      }
    },
  },
}
</script>

<style scoped></style>
