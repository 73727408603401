<template>
  <div class="grid grid-cols-2 gap-2 h-full">
    <div class="grid grid-cols-1 h-full">
      <OveralAssetScoreChart :key="chartKey" :data="overallChartData" />
      <div class="justify-center flex h-full items-center">
        <Button class="px-2 text-sm" @click="openImage">{{
          $t('analysisAndBenchmark.EcorePortfolioScoreButtonText')
        }}</Button>
      </div>
    </div>
    <div>
      <ClusterWeightChart
        :data="clustersData"
        :width="200"
        :height="200"
        :key="chartKey"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import { questionnaires as questionnairesApi } from '@/services/api'
import { useRequest } from '@/composition'
import { EClusterColor } from '@/enums'
import OveralAssetScoreChart from './OveralAssetScoreChart.vue'
import { useRouter } from 'vue-router'
import Button from '@/components/form/button/Button.vue'
import { question as questionHelpers } from '@/helpers'
import ClusterWeightChart from './ClusterWeightChart'

export default {
  components: { OveralAssetScoreChart, Button, ClusterWeightChart },
  props: {
    assetId: {
      type: Number,
      default: 0,
    },
    questionnaireId: {
      type: Number,
      default: 0,
    },
  },
  emit: ['updateTaxonomyScore', 'updateChartKey'],
  setup(props, { emit }) {
    const data = ref()
    const store = useStore()
    const router = useRouter()

    const questionnaireRequest = useRequest(() => {
      const payload = {
        assetId: props.assetId,
        questionnaireId: props.questionnaireId,
        isBuyerAsset: false,
      }
      return store.dispatch('questionnaire/fetchForAsset', payload)
    })

    const getAssetScoreRequest = useRequest(() =>
      questionnairesApi.getAssetScore(
        props.assetId,
        props.questionnaireId,
        false
      )
    )

    const showMessage = message => {
      store.dispatch('toastMessage/showMessage', message)
    }

    const fetchData = async () => {
      try {
        data.value = await getAssetScoreRequest.request()
        emit('updateTaxonomyScore', data.value.taxonomyScore)
        await questionnaireRequest.request()
        chartKey.value += 1 // Update the key to remount the charts
        emit('updateChartKey', chartKey.value)
      } catch {
        showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    }

    const questionnaireData = computed(
      () => store.getters['questionnaire/clusters']
    )

    const overallWeightScore = computed(() => {
      let result = clusters.value.reduce(
        (total, cluster) => (total += cluster.reachedWeightedScore),
        0
      )
      return result
    })

    const clusters = computed(() => {
      if (data?.value?.clusters)
        return data.value.clusters.filter(cluster => !cluster.isTaxonomyCluster)
      else return []
    })

    const overallChartData = computed(() => {
      let chartData = { labels: [], datasets: [], backgroundColor: [] }
      if (clusters.value.length) {
        chartData.labels = [
          ...clusters.value.map(cluster => `CLU${cluster.clusterIdentifier}`),
          '',
        ]
        chartData.datasets = [
          ...clusters.value.map(cluster => cluster.reachedWeightedScore),
          100 - overallWeightScore.value,
        ]
        chartData.backgroundColor = [
          ...clusters.value.map(
            cluster => EClusterColor[`CLU${cluster.clusterIdentifier}`]
          ),
          '#f0f0f0',
        ]
      }
      return chartData
    })

    const clustersData = computed(() => {
      let data = []
      data = clusters.value.map(cluster => {
        const targetCluser = findCluster(cluster.clusterIdentifier)
        if (targetCluser)
          return {
            reachedWeightedScore: cluster.reachedWeightedScore,
            weight: targetCluser.weight,
            name: defaultingLangEntry(targetCluser.name),
            labels: [defaultingLangEntry(targetCluser.name), ''],
            datasets: [
              cluster.reachedWeightedScore,
              targetCluser.weight - cluster.reachedWeightedScore,
            ],
            backgroundColor: [
              EClusterColor[`CLU${cluster.clusterIdentifier}`],
              '#f0f0f0',
            ],
          }
      })
      return data
    })

    const openImage = () => {
      const { href } = router.resolve({ name: 'referenceImage' })
      window.open(href, '_blank')
    }

    const findCluster = clusterIdentifier => {
      return questionnaireData.value?.find(
        clu => clu.clusterIdentifier == clusterIdentifier
      )
    }

    const defaultingLangEntry = langs => {
      return questionHelpers.defaultingLangEntry(langs).name
    }

    const chartKey = ref(0)

    watch(
      () => [props.assetId, props.questionnaireId],
      () => {
        if (props.assetId > 0 && props.questionnaireId > 0) {
          fetchData()
        }
      },
      { immediate: true, deep: true }
    )

    return reactive({
      overallWeightScore,
      clusters,
      overallChartData,
      clustersData,
      openImage,
      chartKey,
    })
  },
}
</script>

<style></style>
