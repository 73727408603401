<template>
  <div class="w-full grid grid-cols-2 gap-x-2 gap-y-4">
    <DoughnutChartDist
      :data="assetTypeDistData"
      :chartHeight="280"
      :title="$t('analysisAndBenchmark.portfolioStructure-classDist')"
    />
    <DoughnutChartDist
      :data="assetsQualityDistData"
      :chartHeight="280"
      :title="$t('analysisAndBenchmark.portfolioStructure-FeatureQualityDist')"
    />
    <div class="flex justify-start">
      <DoughnutChartDist
        :data="countryDistData"
        :rightPadding="60"
        :chartHeight="280"
        :title="$t('analysisAndBenchmark.portfolioStructure-CountryDist')"
      />
    </div>
    <AverageValues :data="data?.averageValue" />
  </div>
</template>

<script>
import { objectGroups as objectGroupApi } from '@/services/api'
import { useRequest } from '@/composition'
import { reactive, ref } from 'vue'
import { mapActions } from 'vuex'
import { useAssetClasses } from '@/composition'
import { pick } from 'lodash-es'
import DoughnutChartDist from './DoughnutChartDist.vue'
import { object as objectHelpers } from '@/helpers'
import AverageValues from './AverageValues.vue'

import {
  EAssetColor,
  EQualityColor,
  EFeatureQuality,
  ECountryColors,
} from '@/enums'

export default {
  components: {
    DoughnutChartDist,
    AverageValues,
  },
  props: {
    groupId: {
      type: String,
    },
  },
  data() {
    return {
      data: null,
      assetClasseList: [],
      assetTypeDistData: {
        labels: [],
        datasets: [],
        backgroundColor: [],
      },
      assetsQualityDistData: {
        labels: [],
        datasets: [],
        backgroundColor: [],
      },
      countryDistData: {
        labels: [],
        datasets: [],
        backgroundColor: [],
      },
    }
  },
  setup(props) {
    const StructureInfoRequest = useRequest(() =>
      objectGroupApi.getStructureInfo(props.groupId)
    )
    const assetClasses = useAssetClasses()
    return reactive({
      StructureInfoRequest,
      assetClasses: pick(assetClasses, ['request', 'pending', 'options']),
    })
  },

  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchData() {
      try {
        this.data = await this.StructureInfoRequest.request()
        await this.assetClasses.request()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
    findAssetTypeName(id) {
      return this.assetClasses.options.find(assetType => assetType.key == id)
        ?.text
    },
    findAssetCode(id) {
      return this.assetClasses.options.find(assetType => assetType.key == id)
        ?.model.assetClassIdentifier
    },
    createAssetTypeDistChart() {
      this.assetTypeDistData.labels = this.data.assetsTypes.map(item =>
        this.findAssetTypeName(item.assetClassId)
      )
      this.assetTypeDistData.datasets = this.data.assetsTypes.map(
        item => item.percentage
      )
      this.assetTypeDistData.backgroundColor = this.data.assetsTypes.map(
        item => EAssetColor[this.findAssetCode(item.assetClassId)]
      )
    },
    createAssetQualityDistChart() {
      this.assetsQualityDistData.labels = this.data.assetsQualities.map(item =>
        objectHelpers.qualityName(item.assetQuality)
      )
      this.assetsQualityDistData.datasets = this.data.assetsQualities.map(
        item => item.percentage
      )
      this.assetsQualityDistData.backgroundColor =
        this.data.assetsQualities.map(
          item => EQualityColor[EFeatureQuality[item.assetQuality]]
        )
    },
    createCountryDistChart() {
      this.countryDistData.labels = this.data.assetsCountries.map(
        item => item.assetCountryCode
      )
      this.countryDistData.datasets = this.data.assetsCountries.map(
        item => item.percentage
      )
      this.countryDistData.backgroundColor = this.data.assetsCountries.map(
        item => ECountryColors[item.assetCountryCode]
      )
    },
  },

  async created() {
    await this.fetchData()
    if (this.data) {
      this.createAssetTypeDistChart()
      this.createAssetQualityDistChart()
      this.createCountryDistChart()
    }
  },
}
</script>

<style></style>
