<template>
  <DynamicView>
    <div>
      <h1 class="text-[2.125rem] font-light text-white mt-[3.25rem]">
        {{ $t('analysisAndBenchmark.benchmarkTitle') }}
      </h1>
      <p class="text-[17px] text-white mt-[1.25rem]">
        {{ $t('analysisAndBenchmark.benchmarkDescription') }}
      </p>
    </div>
    <div class="mx-auto py-2">
      <div class="grid grid-cols-6 gap-2 items-stretch h-full">
        <div class="col-span-3 flex flex-col h-full">
          <BenchmarkDynamicView
            :headline="$t(`analysisAndBenchmark.portfolioStructureInformation`)"
          >
            <PortfolioStructure :groupId="groupId" />
          </BenchmarkDynamicView>
        </div>
        <div class="col-span-2 flex flex-col h-full">
          <BenchmarkDynamicView
            :headline="$t(`analysisAndBenchmark.ecorePortfolioScore`)"
          >
            <EcorePortfolioScore :groupId="groupId" />
          </BenchmarkDynamicView>
        </div>
        <div class="col-span-1 flex flex-col h-full">
          <BenchmarkDynamicView
            :headline="$t(`analysisAndBenchmark.esgPerformancePathway`)"
          >
            <EsgPerformancePath />
          </BenchmarkDynamicView>
        </div>
        <div class="col-span-6 grid grid-cols-6 gap-2 items-stretch h-full">
          <div class="col-span-3 flex flex-col h-full">
            <BenchmarkDynamicView
              :headline="$t(`analysisAndBenchmark.assetData`)"
            >
              <AssetData
                @assetChanged="updateSelectedAsset"
                @questionnaireChanged="updateSelectedQuestionnaire"
                :groupId="groupId"
              />
            </BenchmarkDynamicView>
          </div>
          <div class="col-span-2 flex flex-col h-full">
            <BenchmarkDynamicView
              :headline="$t(`analysisAndBenchmark.ecoreAssetScore`)"
            >
              <AssetScore
                :assetId="selectedAsset"
                :questionnaireId="selectedQuestionnaire"
                @updateTaxonomyScore="event => (taxonomyScore = event)"
                @updateChartKey="event => (chartKey = event)"
              />
            </BenchmarkDynamicView>
          </div>
          <div class="col-span-1 flex flex-col h-full">
            <BenchmarkDynamicView
              :headline="$t(`analysisAndBenchmark.taxonomyCheck`)"
            >
              <TaxonomyCheckScore
                :taxonomyScore="taxonomyScore"
                :chartKey="chartKey"
              />
            </BenchmarkDynamicView>
          </div>
        </div>
        <div class="col-span-2 flex flex-col h-full">
          <BenchmarkDynamicView
            :headline="
              $t(`analysisAndBenchmark.consumptionEmissionsStrandingPoints`)
            "
            class="h-full"
          >
            <ConsumptionEmissionsStrandingPoints
              :assetId="selectedAsset"
              :questionnaireId="selectedQuestionnaire"
            />
          </BenchmarkDynamicView>
        </div>
      </div>
    </div>
  </DynamicView>
</template>

<script>
import DynamicView from '@/components/layout/DynamicView'
import EsgPerformancePath from './EsgPerformancePath.vue'
import BenchmarkDynamicView from './BenchmarkDynamicView.vue'
import ConsumptionEmissionsStrandingPoints from './ConsumptionEmissionsStrandingPoints.vue'
import AssetData from './AssetData.vue'
import PortfolioStructure from './PortfolioStructure/PortfolioStructure.vue'
import EcorePortfolioScore from './EcorePortfolioScore/EcorePortfolioScore.vue'
import TaxonomyCheckScore from './TaxonomyCheckScore/TaxonomyCheckScore.vue'
import AssetScore from './AssetScore/AssetScore.vue'
export default {
  components: {
    EsgPerformancePath,
    DynamicView,
    BenchmarkDynamicView,
    ConsumptionEmissionsStrandingPoints,
    AssetData,
    PortfolioStructure,
    EcorePortfolioScore,
    AssetScore,
    TaxonomyCheckScore,
  },
  data() {
    return {
      selectedAsset: 0,
      selectedQuestionnaire: 0,
      groupId: this.$route.params.groupId,
      taxonomyScore: 0,
      chartKey: 0,
    }
  },
  setup() {
    return {}
  },
  provide() {
    return {}
  },
  computed: {},
  methods: {
    updateSelectedAsset(asset) {
      this.selectedAsset = asset
    },
    updateSelectedQuestionnaire(questionnaire) {
      this.selectedQuestionnaire = questionnaire
    },
  },
  watch: {},
}
</script>
