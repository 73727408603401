<template>
  <div class="chart-container h-full flex justify-center items-center">
    <DoughnutChart
      v-if="data?.datasets?.length"
      :datasets="data.datasets"
      :labels="data.labels"
      :chartOptions="options"
      :backgroundColor="data.backgroundColor"
      :showCenterText="true"
    />
  </div>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { computed } from 'vue'
export default {
  components: {
    DoughnutChart,
  },
  props: {
    data: {
      type: Object,
    },
  },
  setup() {
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          display: false,

          grid: {
            display: false,
          },
        },
        x: {
          display: false,

          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    }))

    return {
      options,
    }
  },
}
</script>

<style></style>
