<template>
  <div v-for="(item, i) in data" :key="i" class="grid grid-cols-3 gap-2 mb-2">
    <div v-if="item" class="flex flex-col space-y-2 col-span-2">
      <div class="flex space-x-1">
        <div
          class="w-5 h-5 rounded-md border border-lightGray"
          :style="{ backgroundColor: item.backgroundColor[0] }"
        ></div>
        <p class="font-bold text-xs">
          {{ ((item.reachedWeightedScore / item.weight) * 100).toFixed(2) }}%
        </p>
      </div>
      <div>
        <p class="text-darkGray font-bold text-xs">{{ item.name }}</p>
        <p class="text-darkGray font-bold text-xs">
          {{ $t('score.metadata-cluster-ownWeight', { weight: item.weight }) }}
        </p>
      </div>
    </div>
    <div class="w-12">
      <DoughnutChart
        v-if="item?.datasets?.length"
        :datasets="item.datasets"
        :labels="item.labels"
        :chartOptions="options"
        :height="50"
        :width="50"
        :backgroundColor="item.backgroundColor"
      ></DoughnutChart>
    </div>
  </div>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { computed } from 'vue'

export default {
  components: { DoughnutChart },
  props: { data: { type: Array } },
  setup() {
    const options = computed(() => ({
      responsive: true,
      cutout: '70%',
      maintainAspectRatio: false,
      scales: {
        y: {
          display: false,

          grid: {
            display: false,
          },
        },
        x: {
          display: false,

          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    }))

    return {
      options,
    }
  },
}
</script>

<style></style>
