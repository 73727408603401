<template>
  <DoughnutChart
    v-if="chartData.datasets?.length"
    v-bind="doughnutChartProps"
    :width="chartWidth"
    :height="chartHeight"
  />
</template>

<script>
import { computed, ref } from 'vue'
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

// Custom plugin to draw text in the center
const centerTextPlugin = {
  id: 'centerText',
  afterDraw(chart) {
    const {
      ctx,
      data,
      options,
      chartArea: { width, height },
    } = chart
    if (data.datasets[0].data.length === 0) {
      return
    }

    const total = data.datasets[0].data
      .slice(0, -1)
      .reduce((acc, val) => acc + val, 0)

    ctx.save()
    ctx.font = 'bold 16px Arial'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#000000'
    const text = total.toFixed(2) + '%'
    const titlePaddingBottom =
      options.plugins.title && options.plugins.title.display
        ? options.plugins.title.padding.bottom + 30 || 0
        : 0
    const textX = width / 2
    const textY = (height + titlePaddingBottom) / 2
    ctx.fillText(text, textX, textY)
    ctx.restore()
  },
}

export default {
  components: { DoughnutChart },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    backgroundColor: {
      type: Array,
      required: false,
    },
    showCenterText: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const dataValues = ref(props.datasets)
    const dataLabels = ref(props.labels)
    const backgroundColor = ref(props.backgroundColor)

    const chartData = computed(() => ({
      labels: dataLabels.value,
      datasets: [
        {
          data: dataValues.value,
          backgroundColor: backgroundColor.value || [
            '#77CEFF',
            '#0079AF',
            '#123E6B',
            '#97B0C4',
            '#A5C8ED',
          ],
        },
      ],
    }))

    const options = computed(() => {
      const baseOptions = {
        responsive: true,
        maintainAspectRatio: false,
        ...props.chartOptions,
      }

      if (props.showCenterText) {
        baseOptions.plugins = {
          ...baseOptions.plugins,
          centerText: {
            display: true,
          },
        }
      }

      return baseOptions
    })

    // Add the centerTextPlugin to the chart options if showCenterText is true
    const chartPlugins = ref([])
    if (props.showCenterText) {
      chartPlugins.value.push(centerTextPlugin)
    }
    const chartWidth = computed(() => props.width)
    const chartHeight = computed(() => props.height)
    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options,
      plugins: chartPlugins.value,
    })

    return {
      chartData,
      doughnutChartRef,
      doughnutChartProps,
      chartWidth,
      chartHeight,
    }
  },
}
</script>

<style></style>
