<template>
  <div>
    <ConsumptionEmissionsStrandingPointsItem
      v-for="(item, index) in data"
      :key="item.assetTypeId"
      :data="item"
      :showHeader="index === 0"
    />
  </div>
</template>

<script>
import ConsumptionEmissionsStrandingPointsItem from './ConsumptionEmissionsStrandingPointsItem.vue'
import { useRequest } from '@/composition'
import { reactive } from 'vue'
import { objects as objectsApi } from '@/services/api'
import { mapActions } from 'vuex'

export default {
  components: {
    ConsumptionEmissionsStrandingPointsItem,
  },
  name: 'ConsumptionEmissionsStrandingPoints',
  props: {
    assetId: {
      type: Number,
      default: 0,
    },
    questionnaireId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  setup(props) {
    const consumptionRequest = useRequest(() =>
      objectsApi.getConsumptionValues(props.assetId, props.questionnaireId)
    )

    return reactive({
      consumptionRequest,
    })
  },
  watch: {
    assetId() {
      if (this.assetId > 0 && this.questionnaireId > 0) this.fetchData()
    },
    questionnaireId() {
      if (this.assetId > 0 && this.questionnaireId > 0) this.fetchData()
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchData() {
      try {
        this.data = await this.consumptionRequest.request()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
  },
}
</script>