<template>
  <div class="chart-container" :key="chartKey">
    <DoughnutChart
      :key="chartKey"
      v-if="data?.datasets?.length"
      :datasets="data.datasets"
      :labels="data.labels"
      :chartOptions="options"
      :height="400"
      :backgroundColor="data.backgroundColor"
      :showCenterText="true"
    />
  </div>
  <div
    class="break-words w-full text-center mt-12 font-bold"
    :class="[taxonomyScore === 100 ? 'text-cyan' : 'text-error']"
  >
    <UnderlineFirstWord
      :text="
        $t(
          `analysisAndBenchmark.taxonomyScore-${
            taxonomyScore === 100 ? 'compliant' : 'nonCompliant'
          }`
        )
      "
      :underline="taxonomyScore !== 100"
    ></UnderlineFirstWord>
  </div>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { computed } from 'vue'
import UnderlineFirstWord from '@/components/form/UnderlineFirstWord.vue'
export default {
  components: {
    DoughnutChart,
    UnderlineFirstWord,
  },
  props: {
    taxonomyScore: {
      type: Number,
    },
    chartKey: {
      type: Number,
    },
  },
  setup(props) {
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          display: false,

          grid: {
            display: false,
          },
        },
        x: {
          display: false,

          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    }))

    const data = computed(() => {
      let chartData = { labels: [], datasets: [], backgroundColor: [] }
      chartData.datasets = [props.taxonomyScore, 100 - props.taxonomyScore]
      chartData.backgroundColor = [
        `${props.taxonomyScore == 100 ? '#1495ff' : '#ff1744'}`,
        '#f0f0f0',
      ]
      return chartData
    })
    return {
      options,
      data,
    }
  },
}
</script>

<style></style>
